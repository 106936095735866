<template>
    <div id="aboutPage">
        <div class="usViewsF">
            <div class="viewsContent">
                <img class="logoImg" src="https://img.qddfxfpx.com:8448/appimg/aboutus/QDlogo.png" alt="">
            </div>
        </div>
        <div class="usViewsC">
            <div class="viewsContent1">
                <div class="content1">
                    <div class="content1Title" style="background:url(https://img.qddfxfpx.com:8448/appimg/aboutus/gather.png);background-size:100%">
                        <div class="content2Title2">消防人才的摇篮</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="usViewsF">
            <div class="viewsContent">
                <div class="content2">
                    <div class="content2Title1">科技引领消防培训数字化创新</div>
                    <div class="content2Title2">独家研发</div>
                    <div class="content2Title2">智能学习系统 + 实景考场</div>
                    <div class="content2Title1">消防设施操作员国考</div>
                    <div class="content2Title3">随时随地     省时省力</div>
                    <div class="content2Title3">应试神器     5步通关</div>
                </div>
                <div class="content3">
                    <img src="https://img.qddfxfpx.com:8448/appimg/aboutus/circle.png" />
                </div>
            </div>
        </div>
        <div class="usViewsC">
            <div class="viewsContent">
                <div class="content2">
                    <div class="content2Title1 topLanger">卓越教学理念：教考试+教本事</div>
                    <div class="content2Title2">独家研发升职加薪宝</div>
                    <div class="content2Title1 topLanger">服务消防人才终身学习和职业发展</div>
                </div>
                <div class="content4">
                    <div class="viewsContent4Btn">卓越岗位课程</div>
                    <div class="viewsContent4Btn">领导管理课程</div>
                </div>
            </div>
        </div>
        <div class="usViewsF">
            <div class="viewsContent">
                <div class="content5">
                    <div class="content2Title2">培训项目<h3></h3></div>
                </div>
                <div class="content7">
                    <div class="content7Card" v-for="(item,index) in categoryList" :key="index">
                        <div class="cardImage7">
                            <img :src="item.viewImage" />
                        </div>
                        <div class="cardConAndBtn">
                            <div class="cardCon">{{item.category_name}}</div>
                            <div class="cardBtn">
                                <div class="cardOnline" @click="consulOnline(item)">在线咨询</div>
                                <div class="cardGoApply" @click="goApply(item)">立即报名</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="usViewsC">
            <div class="viewsContents">
                <div class="content2Title2">标杆客户<h3></h3></div>
                <div class="cardimg">
                    <img src="https://img.qddfxfpx.com:8448/appimg/aboutus/clientlist.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="usViewsC">
            <div class="viewsContent">
                <div class="content6">
                    <div class="content2Title2">联系我们<h3></h3></div>
                </div>
                <div class="content8">
                    <div class="cont8Left">
                        <div class="leftTitle8">消防培训咨询热线</div>
                        <div class="leftPhone8" @click="dialPhone">4001-888-119</div>
                    </div>
                    <div class="cont8Right">
                        <div class="leftTitle8">微信服务号</div>
                        <div class="rightImage" @click="viewImage">
                            <img src="https://img.qddfxfpx.com:8448/appimg/aboutus/code.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            categoryList: [
                {category_id: 1024,category_name: "消防设施操作员培训",viewImage: "https://img.qddfxfpx.com:8448/appimg/aboutus/card1.png"},
                {category_id: 1026,category_name: "社会消防安全教育培训",viewImage: "https://img.qddfxfpx.com:8448/appimg/aboutus/card2.png"},
                {category_id: 5302,category_name: "消防员培训",viewImage: "https://img.qddfxfpx.com:8448/appimg/aboutus/card3.png"},
                {category_id: 1025,category_name: "注册消防工程师培训",viewImage: "https://img.qddfxfpx.com:8448/appimg/aboutus/card4.png"},
                {category_id: 19639,category_name: "消防专业学历教育",viewImage: "https://img.qddfxfpx.com:8448/appimg/aboutus/card5.png"},
            ],
        }
    },
    methods: {
        // 在线咨询
        consulOnline(item) {
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.openOnlineConsul) {
                window.webkit.messageHandlers.openOnlineConsul.postMessage(JSON.stringify(item.category_id));
            }
            if (window.AndroidApp) {
                window.AndroidApp.openOnlineConsul(JSON.stringify(item.category_id))
            }
        },
        // 立即报名
        goApply(item) {
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.openApply) {
                window.webkit.messageHandlers.openApply.postMessage(JSON.stringify(item.category_id));
            }
            if (window.AndroidApp) {
                window.AndroidApp.openApply(JSON.stringify(item.category_id))
            }
        },
        // 消防培训咨询热线
        dialPhone() {
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.dialPhone) {
                window.webkit.messageHandlers.dialPhone.postMessage("4001888119");
            }
            if (window.AndroidApp) {
                window.AndroidApp.dialPhone("4001888119")
            }
        },
        // 微信服务号
        viewImage() {
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.viewImage) {
                window.webkit.messageHandlers.viewImage.postMessage("https://img.qddfxfpx.com:8448/appimg/aboutus/code.jpg");
            }
            if (window.AndroidApp) {
                window.AndroidApp.viewImage("https://img.qddfxfpx.com:8448/appimg/aboutus/code.jpg")
            }
        },
    },
    mounted() {
        var width = document.getElementById("aboutPage").offsetWidth
        console.log("width",width)
    }
}
</script>
<style lang="stylus" scoped>
    .usViewsF{
        width: 100%;
        background-color: #fff;
    }
    .usViewsC{
        min-width: 345px;
        margin: 0 auto;
        background-color: #f1f1f1;
    }
    .viewsContent{
        width: 345px;
        margin: 0 auto;
        text-align: center;
    }
    .viewsContent1{
        width: 357px;
        margin: 0 auto;
        text-align: center;
    }
    .viewsContents{
        margin: 0 auto;
        background-color:#eeeeef;
        width: 345px;
        text-align: center;
        box-sizing: border-box;
        padding: 50px 0;
    }
    .cardimg img{
        width: 100%;
        height:750px;
    }
    .viewsContents .content2Title2{
        padding-bottom: 25px;
    }
    .logoImg{
        width: 226px;
        height: 55px;
        padding: 20px 0;
    }
    .content1Title{
        text-align: center;
        height: 362px;
    }
    .content1{
        padding: 30px 0;
    }
    .content1Card{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .cardLeft{
        width: 165px;
        height: 100px;
    }
    .cardLeft image{
        width: 165px;
        height: 100px;
    }
    .cardRight{
        width: 165px;
        height: 100px;
        background-color: pink;
        border-radius: 5px;
    }
    .content2{
        text-align: center;
        line-height: 35px;
        padding: 40px 0 15px;
    }
    .content3{
        padding-bottom: 30px;
    }
    .content3 img{
        width: 345px;
        height: 320px;
    }
    .content2Title1{
        font-size: 16px;
        color: #444;
    }
    .topLanger{
        padding-top: 10px;
    }
    .content2Title2{
        font-size: 25px;
        color: #e62f40;
    }
    .content2Title2 h3{
        font-weight: bold;
    }
    .content2Title3{
        font-size: 14px;
        color: #555;
    }
    .content3Image{
        display: flex;
        justify-content: center;
    }
    .content3Image image{
        width: 120px;
        height: 120px;
        margin: 5px 8px;
    }
    .gatherImage{
        text-align: center;
    }
    .gatherImage image{
        width: 345px;
        height: 275px;
    }
    .content4{
        width: 325px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        padding-bottom: 60px;
        padding-top: 15px;
    }
    .viewsContent4Btn{
        padding: 7px 25px;
        border: 1px solid #598AF8;
        color: #598AF8;
        font-size: 15px;
    }
    .content5,.content6{
        text-align: center;
        padding-top: 50px;
    }
    .content7{
        padding: 20px 0 50px;
    }
    .content7Card{
        display: flex;
        justify-content: space-between;
        margin: 22px 0;
    }
    .cardImage7{
        width: 150px;
        height: 70px;
    }
    .cardImage7 img{
        width: 150px;
        height: 70px;
    }
    .cardConAndBtn{
        width: 175px;
    }
    .cardCon{
        font-size: 14px;
        text-align: left;
    }
    .cardBtn{
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        font-weight: bold;
        font-size: 14px;
    }
    .cardOnline{
        background-color: #FDE9D1;
        color: #e62f40;
        line-height: 26px;
        height: 26px;
        padding: 0;
        width: 80px;
        font-size: 14px;
        border-radius: 13px;
        margin-left: 0!important;
        margin-right: 0!important;
    }
    button::after{
        border: 0;
    }
    .cardGoApply{
        background-color: #e62f40;
        color: #fff;
        width: 80px;
        height: 26px;
        line-height: 26px;
        font-size: 13px;
        border-radius: 13px;
    }
    .content8{
        padding: 25px 0 90px;
        display: flex;
        justify-content: space-between;
    }
    .cont8Left{
        width: 145px;
        text-align: center;
    }
    .leftPhone8{
        padding: 5px 0;
        width: 145px;
        border: 1px solid #e62f40;
        margin: 35px auto 0;
        border-radius: 15px;
        color: #e62f40;
    }
    .cont8Right{
        width: 150px;
        text-align: center;
    }
    .cont8Right .rightImage img{
        width: 75px;
        height: 75px;
        margin-top: 10px;
    }
</style>
