var SIGN_REGEXP = /([yMdhsm])(\1*)/g;
var DEFAULT_PATTERN = 'yyyy-MM-dd';
function padding(s, len) {
    var len = len - (s + '').length;
    for (var i = 0; i < len; i++) { s = '0' + s; }
    return s;
};
export default {
    getQueryStringByName: function (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        var context = "";
        if (r != null)
            context = r[2];
        reg = null;
        r = null;
        return context == null || context == "" || context == "undefined" ? "" : context;
    },
    formatDate: {
        format: function (date, pattern) {
            pattern = pattern || DEFAULT_PATTERN;
            return pattern.replace(SIGN_REGEXP, function ($0) {
                switch ($0.charAt(0)) {
                    case 'y': return padding(date.getFullYear(), $0.length);
                    case 'M': return padding(date.getMonth() + 1, $0.length);
                    case 'd': return padding(date.getDate(), $0.length);
                    case 'w': return date.getDay() + 1;
                    case 'h': return padding(date.getHours(), $0.length);
                    case 'm': return padding(date.getMinutes(), $0.length);
                    case 's': return padding(date.getSeconds(), $0.length);
                }
            });
        },
        parse: function (dateString, pattern) {
            var matchs1 = pattern.match(SIGN_REGEXP);
            var matchs2 = dateString.match(/(\d)+/g);
            if (matchs1.length == matchs2.length) {
                var _date = new Date(1970, 0, 1);
                for (var i = 0; i < matchs1.length; i++) {
                    var _int = parseInt(matchs2[i]);
                    var sign = matchs1[i];
                    switch (sign.charAt(0)) {
                        case 'y': _date.setFullYear(_int); break;
                        case 'M': _date.setMonth(_int - 1); break;
                        case 'd': _date.setDate(_int); break;
                        case 'h': _date.setHours(_int); break;
                        case 'm': _date.setMinutes(_int); break;
                        case 's': _date.setSeconds(_int); break;
                    }
                }
                return _date;
            }
            return null;
        }
    },
    isEmt:{
        format: function (obj) {
            if(typeof obj == "undefined" || obj == null || obj == ""){
                return true;
            }else{
                return false;
            }
        },
    },
    // 根据特殊字符'|'对传入的obj字符切割方法
    getCaption(obj,state) {
        var index=obj.lastIndexOf("\|");
        if(state==0){
            obj=obj.substring(0,index);
        }else {
            obj=obj.substring(index+1,obj.length);
        }
        return obj;
    },
    // 对字符加密
    compileStr(code){
        var c=String.fromCharCode(code.charCodeAt(0)+code.length);
         for(var i=1;i<code.length;i++)
        { 
        c+=String.fromCharCode(code.charCodeAt(i)+code.charCodeAt(i-1));
         } 
        return escape(c); 
    },
    //字符串进行解密 
    uncompileStr(code){ 
        code=unescape(code); 
        var c=String.fromCharCode(code.charCodeAt(0)-code.length); 
        for(var i=1;i<code.length;i++)
        { 
        c+=String.fromCharCode(code.charCodeAt(i)-c.charCodeAt(i-1)); 
        } 
        return c; 
    },
    // 根据身份证获取相关信息
    getAnalysisIdCard(card, num) {
        if (num == 1) {
            //获取出生日期
            let birth = card.substring( 6, 10) + "-" + card.substring( 10, 12) + "-" + card.substring( 12, 14);
            return birth;
        }
        if (num == 2) {
            //获取性别
            if (parseInt( card.substr( 16, 1)) % 2 == 1) {
                //男
                return 1;
            } else {
                //女
                return 0;
            }
        }
        if (num == 3) {
            //获取年龄
            var myDate = new Date();
            var month = myDate.getMonth() + 1;
            var day = myDate.getDate();
            var age = myDate.getFullYear() - card.substring( 6, 10) - 1;
            if ( card.substring( 10, 12) < month || card.substring( 10, 12) == month && card.substring( 12, 14) <= day) {
                age++;
            }
            return age;
        }
    },
    // 去掉特殊符号及空格
    validForbid(value, number = 2550) {
        value = value
        .replace(
            /[`~!@#$%^&*()_\+=<>?:"{}|,/;'\\[\]~！@#￥%……&*（）——\+={}|《》？：“”【】、；‘’，。、]/g,""
        )
        .replace(/\s/g, "");
        if (value.length >= number) {
            this.$message({
            type: "warning",
            message: `输入内容不能超过${number}个字符`,
            });
        }
        return value;
    },
};
